.product-title {
  font-size: 24px;
  font-weight: bolder;
  line-height: 1.13;
  color: var(--color-black);
}

.product-description {
  line-height: 1;
}

.hart-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

/* .product-main-list {
  margin-top: 4.5rem !important;
} */
.homepage-product-list-wrap {
  padding: 24px;
  position: relative;
  min-height: 150px;
}

.cart-pg-mb-30 {
  margin-bottom: 24px;
}

.homepage-pg-list-image {
  position: relative;
}

.product-header {
  display: flex;
  justify-content: space-between;
}

.cart-pg-list-prdt-info {
  width: 75%;
}

.cart-prodict-info .cart-product-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
  /* margin-bottom: 20px; */
  margin-bottom: 0px;
  max-width: 310px;
  text-align: left;
  font-style: normal;
}

.home-product-image {
  border-radius: 10px;
}

.my-product-price {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1.08px;
  /* color: var(--color-RegularActiveText); */
  color: #000000;
  /* font-family: Rubik; */
  font-style: normal;
}

.my-product-actual-price {
  /* font-size: 20px; */
  font-size: 16px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: gray;
  text-decoration: line-through;
  padding-left: 5px;
}

.my-product-discount {
  /* font-size: 20px; */
  font-size: 15px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: #f67054;
  padding-left: 5px;
}

.homepage-product-list-other {
  position: relative;
  min-width: 160px;
}

.cart-product-delete {
  font-size: 16px;
  color: #a3a3a3;
  cursor: pointer;
}

.cart-quantity-box {
  position: absolute;
  bottom: 0;
  right: 0;
  /* width: 115px; */
}

.radius-5 {
  border-radius: 5px;
}

.ratingTag {
  margin-left: 5px;
  position: absolute;
  background-color: #ffa500;
  /* border-radius: 15px; */
  font-size: 12px;
  color: white;
  /* padding: 4px 10px 2px 4px !important; */
  width: 43.5px;
  height: 17.9px;
  margin: 4px 18.2px 14.1px 9px;
  padding: 2px 5.5px 1.9px 7px;
  text-align: center;
  position: inherit;
  border-radius: 10px;
}

.cart-prodict-info .food-subtitle {
  /* font-size: 15px; */
  font-size: 14px;
  color: #000000;
  /* max-height: 50px;
  overflow-y: scroll; */
  /* scroll-behavior: smooth; */
  /* word-break: break-all; */
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
  margin-bottom: 12px;
  /* height: 30px; */
}

.my-toggle-class {
  display: flex;
}

.food-subtitle::-webkit-scrollbar {
  width: 2px;
}

.food-subtitle::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  width: 1px;
}

.food-subtitle::-webkit-scrollbar-thumb {
  background: var(--color-gray);
}

.food-subtitle::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-grey);
}

.review-date {
  color: #7c8188;
}

.show-more-text {
  font-weight: 700;
  cursor: pointer;
  color: var(--button-primary-hover) !important;
  /* text-decoration: none; */
}

.add-button {
  padding: 10px 40px !important;
  background-color: #f2f6f8 !important;
  font-size: 18px !important;
  border: none !important;
  color: var(--color-black) !important;
}

.filter-price-bt-left {
  position: absolute;
  bottom: 0;
}

.filter-price-bt-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.added-button {
  padding: 10px 40px !important;
  background-color: var(--button-primary-hover) !important;
  color: var(--button-text-primary-hover) !important;
  font-size: 18px !important;
  border: none !important;
}

.add-button:hover {
  transition: 0.5s ease;
  background-color: var(--button-primary) !important;
  color: var(--button-text-primary) !important;
}

.customize-product-label {
  float: right;
  color: #f67054;
  font-size: 13px;
}

.wishlistLogo {
  float: right;
  object-fit: contain;
}

.wishlistLogo:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.1);
}

.my-filter-text {
  display: flex;
  flex-direction: column;
}

.out-of-stock-span {
  font-size: 18px;
  color: #FC5185;
  font-weight: 500;
}

.load-more-option {
  text-align: center;
  padding: 30px;
}

.load-more-option span {
  cursor: pointer;
  font-size: 20px;
  transition: letter-spacing 0.3s ease-in-out;
}

.load-more-option span:hover {
  letter-spacing: 1px;
}

.load-more-option span svg {
  color: #f67054;
  transition: opacity 0.5s ease-in-out;
}

.my-product-animator {
  opacity: 0;
}

.load-more-option span:hover .my-product-animator {
  opacity: 1;
}

.clear-filter-label {
  font-size: 15px;
  color: #f67054;
  cursor: pointer;
  background-color: white;
  padding: 5px;
  border-radius: 25px;
  width: 125px;
  border: 1px solid #f67054;
}

.clear-filter-label svg {
  font-size: 22px;
}

.clear-filter-label:hover svg {
  transition: all 0.5s ease;
  transform: rotate(360deg);
}

.product-search {
  text-transform: capitalize;
}

.product-search label {
  font-size: 25px;
  color: gray;
  padding-right: 5px;
}

.food-filter-tag {
  padding: 10px 15px;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  font-size: 15px !important;
  margin-right: 12px;
  cursor: pointer;
  color: #7c8188;
}

.food-filter-tag.active {
  color: white;
  background-color: #f67054;
}

.food-filter-tag img {
  padding-right: 5px;
}

.myFavouritesTag {
  line-height: 2.3;
  margin-right: 12px;
}

.tag-divider {
  border-right: 1px solid #dbdbdb;
  margin-right: 10px;
}

/*MODAL STYLING START*/
.add-addr-title-bar {
  border-color: #dbdbdb;
  border-width: 2px;
}

.add-addr-title-bar span {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}

.ad-addr-body-wrap .form-group .form-control {
  height: auto;
  border: none;
  border-bottom: 2px solid #dbdbdb;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-black);
}

.ad-addr-body-wrap .profile-form-tag {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}

.add-addr-btn {
  opacity: 0.99 !important;
  border-radius: 6px !important;
  background-color: var(--button-primary) !important;
  color: var(--button-text-primary) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 1.11 !important;
  margin-top: 30px !important;
}

.add-addr-btn:hover {
  background-color: var(--button-primary-hover) !important;
  color: var(--button-text-primary-hover) !important;
}

.modal-food-mainhead {
  padding: 15px 25px;
  display: flex;
}

.modal-review-mainhead {
  padding: 15px 25px;
  display: flex;
  border-bottom: 1px solid #dbdbdb;
}

.modal-review-mainhead:last-child {
  border: none;
}

.rating-star-ul {
  list-style: none;
  display: flex;
  padding: 0;
}

.rating-svg {
  fill: #ffa500;
}

.margin-left-1 {
  margin-left: 2px;
}

.modal-attributes-section {
  padding: 15px 25px;
}

.modal-attributes-section span {
  font-size: 15px !important;
}

.modal-section-divider {
  padding: 0.5px;
  background-image: linear-gradient(to right,
      #dbdbdb 0%,
      #dbdbdb 50%,
      transparent 50%);
  background-size: 10px 1px;
}

.modal-food-heading {
  padding-left: 5px;
  line-height: 0.1;
  text-transform: capitalize;
}

.modal-rating-heading {
  padding-left: 5px;
  line-height: 0.1;
  display: flex;
}

.review-user-png {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-rating-heading small {
  color: var(--color-dark-grey);
  font-size: 15px;
}

.modal-food-heading h3 {
  color: var(--color-black);
}

.modal-food-heading small {
  color: var(--color-dark-grey);
  font-size: 15px;
  line-height: 1.1;
}

.yt-add-modal-body {
  padding: 0% !important;
}

.form-check-input {
  float: right;
}

.ad-addr-body-wrap .form-group:last-child {
  margin-bottom: 0 !important;
}

.ad-addr-body-wrap .form-group .form-check-input:checked {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.ad-addr-body-wrap .form-group .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem #3f7a4f54;
}

.modal-footer {
  display: inline-block !important;
}

.item-total {
  float: left;
}

.item-total span {
  font-size: 15px;
  color: var(--color-orange);
}

.item-total-flex {
  display: flex;
  flex-direction: row;
}

.item-total-flex div {
  font-size: 20px;
  color: var(--color-RegularActiveText);
}

.item-total-flex span {
  font-size: 12px;
  padding-top: 6%;
  color: var(--color-dark-grey);
  margin-left: 4px;
}

.modal-addtocart-btn {
  float: right;
  height: 45px;
  width: 45%;
  font-size: 18px !important;
  border: none !important;
  border-color: #364f6b !important;
  background-color: #364f6b !important;
  color: #fff !important;
}

.modal-addtocart-btn:hover {
  transition: 0.3s ease;
  border-color: #364f6b !important;
  background-color: #576c85 !important;
  color: #fff !important;
}

.toggle-Modal {
  cursor: pointer;
}

.box-shadow {
  box-shadow: 0px 4px 4px #f5f5f5 !important;
}

/*MODAL STYLING END*/

/*Cart Quantity Start*/
/*Quantity CSS*/
.cart-quantity-field .form-group {
  position: relative;
  border-radius: 5px;
  /* border: solid 1px var(--button-primary-hover); */
  border: solid 1px #dbdbdb;
}

.cart-quantity-icn {
  position: absolute;
  top: 1px;
  bottom: 1px;
  cursor: pointer;
}

/* .cart-quantity-icn svg {
  fill: white;
} */
.cart-quantity-icn-left svg {
  fill: rgb(143, 143, 143);
}

.cart-quantity-icn-right svg {
  fill: white;
}

.quantity-icn-left {
  left: 1px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background: rgba(200, 200, 200, 1.5);
}

.quantity-icn-right {
  right: 1px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background-color: var(--button-primary-hover);
  box-sizing: border-box;
  color: var(--button-text-primary-hover);
}

.quantity-icn-right:hover {
  transition: 0.5s ease;
  background-color: var(--button-primary) !important;
  color: var(--button-text-primary) !important;
}

.cart-quantity-field .form-control {
  border-radius: 10px;
  box-shadow: none !important;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
}

.cart-quantity-field input::-webkit-outer-spin-button,
.cart-quantity-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-quantity-field {
  /* color: #aaaaaa; */
  font-weight: 500;
}

/* Firefox */
.cart-quantity-field input[type="number"] {
  -moz-appearance: textfield;
}

.cart-quantity-field {
  max-width: 120px;
}

/*Cart Quantity End*/

.img-pro {
  width: 200px;
  height: 170px;
}

.img-pro img {
  max-width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 10px;
}

.btn-add {
  height: 40px;
  width: 79px;
  padding: 5px 23px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px !important;
  box-sizing: border-box;
  color: #000;
}

.btn-add:hover {
  transition: 0.5s ease;
  height: 40px;
  width: 79px;
  padding: 5px 23px;
  background-color: #364F6B;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px !important;
  box-sizing: border-box;
  color: #fff;
  box-sizing: border-box
}

.mr-35 {
  margin-right: 35px;
}

.veg-product span {
  font-size: 12px;
}

.veg-nonVeg span {
  /* font-family: Rubik; */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #000000;
}

.pr-8 {
  padding-right: 8px;
}

@media (min-width: 1900px) {
  .homepage-product-list-wrap {
    /*Column Two*/
    padding: 24px;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .product-title {
    /*Column Two*/
    font-size: 24px;
    line-height: 1.12;
  }

  .homepage-product-list-wrap {
    /*Column Two*/
    padding: 24px;
    margin-bottom: 24px !important;
  }

  .cart-product-title {
    /*Column Two*/
    line-height: 1.67;
    max-width: 310px;
  }

  .ad-addr-body-wrap .cm-edit-add-col {
    /*MODAL*/
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }

  .ad-addr-body-wrap {
    /*MODAL*/
    padding: 8px 0;
  }

  .ad-addr-body-wrap .form-group {
    /*MODAL*/
    margin-bottom: 15px;
  }

  .ad-addr-body-wrap .add-addr-btn {
    /*MODAL*/
    margin-top: 0 !important;
    font-size: 16px !important;
    line-height: 1.13 !important;
  }
}

@media (max-width: 1279.92px) {
  .cart-pg-list-prdt-info {
    width: 65%;
  }

  .homepage-pg-list-image {
    width: auto;
  }

  .product-title {
    /*Column Two*/
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 14px;
  }

  .homepage-product-list-wrap {
    /*Column Two*/
    padding: 15px;
    margin-bottom: 10px;
  }

  .cart-product-title {
    /*Column Two*/
    font-size: 16px;
    line-height: 1.13;
  }

  .my-product-price {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.77px;
    font-weight: 500;
    font-style: normal;
  }

  .add-addr-title-bar span {
    /*MODAL*/
    font-size: 18px;
    line-height: 1.11;
  }

  .ad-addr-body-wrap .profile-form-tag {
    /*MODAL*/
    font-size: 14px;
    line-height: 1.14;
  }

  .ad-addr-body-wrap .form-group .form-control {
    /*MODAL*/
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px;
    line-height: 1.11;
  }

  .add-addr-btn {
    /*MODAL*/
    margin-top: 30px !important;
    font-size: 16px !important;
    line-height: 1.13 !important;
    letter-spacing: normal !important;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }

  .cart-quantity-field {
    /*Cart Quantity*/
    max-width: 100px;
    margin-left: auto;
  }
}

@media (max-width: 1024px) {
  .cart-pg-list-prdt-info {
    width: 67%;
  }

  .homepage-pg-list-image {
    width: auto;
  }
}

@media (max-width: 800px) {
  .cart-pg-list-prdt-info {
    width: 72%;
  }

  .homepage-pg-list-image {
    width: auto;
  }
}

@media (min-width: 768px) {
  .homepage-pg-list-image .homepage-product-image img {
    /*Column Two*/
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .homepage-pg-list-image .homepage-product-image {
    /*Column Two*/
    width: 142px;
    height: 142px;
  }
}

@media (max-width: 767.92px) {
  .cart-pg-list-prdt-info {
    width: 73%;
  }

  .homepage-pg-list-image {
    width: auto;
  }

  .product-title {
    /*Column Two*/
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 1.11;
    padding-left: 8px;
    display: block;
  }

  .product-header {
    margin-top: 15px;
  }

  .homepage-product-list-wrap {
    /*Column Two*/
    padding: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .homepage-pg-list-image .homepage-product-image img {
    /*Column Two*/
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .homepage-pg-list-image .homepage-product-image {
    /*Column Two*/
    width: 120px;
    height: 120px;
  }

  .cart-product-title {
    /*Column Two*/
    font-size: 14px;
    line-height: 1.36;
  }

  .my-product-price {
    /*Column Two*/
    font-size: 15px;
    line-height: 1.2;
  }

  .cart-product-delete svg {
    /*Column Two*/
    font-size: 15px;
  }

  .add-addr-title-bar span {
    /*MODAL*/
    font-size: 18px;
    line-height: 1.11;
  }

  .ad-addr-body-wrap .profile-form-tag {
    /*MODAL*/
    font-size: 13px;
    line-height: 1.15;
  }

  .ad-addr-body-wrap .form-group .form-control {
    /*MODAL*/
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 17px;
    line-height: 1.12;
  }

  .ad-addr-body-wrap .yt-fp-form .form-group {
    /*MODAL*/
    margin-bottom: 20px !important;
  }

  .add-addr-btn {
    /*MODAL*/
    margin-top: 20px !important;
    font-size: 14px !important;
    line-height: 1.14 !important;
    letter-spacing: 0.4px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .cart-quantity-field {
    /*Cart Quanity*/
    max-width: 88px;
    margin-left: auto;
  }

  .myFavouritesTag {
    line-height: 1;
  }

  .my-toggle-class {
    display: inline-grid;
  }

  .clear-filter-label {
    font-size: 10px;
    margin-left: 0;
    padding: 1px;
    width: 80px;
  }

  .clear-filter-label svg {
    font-size: 15px;
  }

  .add-button {
    padding: 3px 40px !important;
    font-size: 16px !important;
  }

  .cart-prodict-info .cart-product-title {
    font-size: 16px;
  }

  .homepage-product-list-other {
    min-width: auto;
  }
}

@media (max-width: 500px) {
  .homepage-pg-list-image .homepage-product-image {
    /*Column Two*/
    width: 70px;
    height: 70px;
  }

  .hart-icon {
    position: absolute;
    top: 3px;
    right: 0px;
  }

  .homepage-product-list-wrap {
    /*Column Two*/
    padding: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}