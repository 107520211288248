.yt-main-contact-us-pg .contactform {
  padding: 25px 20px;
}

.yt-main-contact-us-pg #isAgree .conatctus-checkbox {
  position: absolute;
  left: -335px;
  /* bottom: -67px; */
  background: var(--color-green) !important;
  /* top: 115px; */
}

.conatct-pg {
  padding: 2%;
}

.contact-bredCrum {
  color: var(--color-black) !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
}

.promotions-ContactUs {
  position: absolute;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 90px;
  flex-direction: row;
}

.pp-contact-checkbox {
  position: absolute;
  left: -332px;
  margin-top: 1.9%;
}

.conatctus-checkbox .form-group input[type="checkbox"] {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.yt-main-contact-us-pg input[type="checkbox"] {
  font-size: 18px;
}

.conatct-checkbox-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.11 !important;
  border: none !important;
  border-radius: 6px !important;
  /* position: absolute !important;
  bottom: -82px !important;
  left: 0 !important; */
  color: var(--color-dark-grey);
}

.yt-main-contact-us-pg .title {
  margin-bottom: 30px;
  color: var(--color-black);
  font-size: 30px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13 !important;
  letter-spacing: normal;
  text-align: left;
}

.yt-main-contact-us-pg .form {
  background-color: white;
  padding: 50px 40px 20px;
}

.yt-main-contact-us-pg textarea {
  border-radius: 8px;
  padding: 12px;
  margin: 0px 9px;
  width: 100%;
  border-color: #c0bebe;
  font-size: 15px;
  height: 100px;
}

.yt-main-contact-us-pg textarea::placeholder {
  color: #999;
}

.yt-main-contact-us-pg .send-button {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

/* form starting stylings ------------------------------- */

.yt-main-contact-us-pg .group {
  position: relative;
  margin-bottom: 30px;
}

.yt-main-contact-us-pg input {
  font-size: 16px;
  padding: 10px 10px 16px 0px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #c0bebe;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.yt-main-contact-us-pg input:focus {
  outline: none;
}

/* LABEL ======================================= */

.yt-main-contact-us-pg label {
  color: #999;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */

.yt-main-contact-us-pg input:focus~label,
.yt-main-contact-us-pg input:valid~label {
  top: -20px;
  font-size: 14px;
  color: black;
}

/* BOTTOM BARS ================================= */

.yt-main-contact-us-pg .bar {
  position: relative;
  display: block;
  width: 100%;
}

.yt-main-contact-us-pg .bar:before,
.yt-main-contact-us-pg .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: var(--color-RegularActiveText);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.yt-main-contact-us-pg .yt-main-contact-us-pg .bar:before {
  left: 50%;
}

.yt-main-contact-us-pg .bar:after {
  right: unset;
}

/* active state */

.yt-main-contact-us-pg input:focus~.bar:before,
.yt-main-contact-us-pg input:focus~.bar:after {
  width: 100%;
}

/* HIGHLIGHTER ================================== */

.yt-main-contact-us-pg .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */

.yt-main-contact-us-pg input:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

.yt-main-contact-us-pg textarea:focus-visible {
  outline-color: var(--color-RegularActiveText);
}

.checkbox-ContactUs {
  display: grid;
  margin: 15px !important;
}

/* ANIMATIONS ================ */

@-webkit-keyframes inputHighlighter {
  from {
    background: var(--color-RegularActiveText);
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: var(--color-RegularActiveText);
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: var(--color-RegularActiveText);
  }

  to {
    width: 0;
    background: transparent;
  }
}

.yt-main-wrapper2 {
  margin: 0 auto;
  position: relative;
}

.yt-main-contact-us-pg {
  padding-top: 20px;
  margin-bottom: 160px;
}

.yt-contact-send-btn {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 1.11 !important;
  border: none !important;
  padding: 15px 70px !important;
  border-radius: 6px !important;
  position: absolute !important;
  bottom: -82px !important;
  right: 0 !important;
  border-color: #364f6b !important;
  background-color: #364f6b !important;
  color: #fff !important;
}

.yt-contact-send-btn:hover {
  border-color: #364f6b !important;
  background-color: #576c85 !important;
  color: #fff !important;
}

@media (min-width: 1900px) {
  .yt-main-contact-us-pg {
    padding-top: 80px;
  }
}

@media (min-width: 768px) {
  .yt-cm-mobile-bread {
    /* display: none; */
  }
}

/*
  * Screen for under 1280 Resolution 
  */

@media (max-width: 1279.92px) {
  .yt-main-contact-us-pg {
    padding-top: 44px;
    margin-bottom: 110px;
  }

  .yt-main-contact-us-pg .title {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 20px;
  }

  .yt-main-contact-us-pg .form {
    padding: 20px 15px;
  }

  .yt-main-contact-us-pg label {
    font-size: 12px;
    line-height: 1.17;
  }

  .yt-contact-send-btn {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 1.13 !important;
    letter-spacing: 0.32px !important;
  }

  .yt-main-contact-us-pg input {
    padding: 10px 10px 16px 6px;
  }

  .promotions-ContactUs {
    margin-top: 55px;
  }
}

/*
  * Screen for under 768 Resolution 
  */

@media (max-width: 767.92px) {
  .pp-contact-checkbox {
    margin-top: -0.1%;
    left: -177px;
  }

  .conatct-checkbox-text {
    font-size: 10px !important;
    padding: 0 !important;
  }

  .pageroute {
    color: gray;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 1.17;
  }

  .yt-profile-mb-ttl.profile-pg-title {
    padding-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.11;
  }

  .yt-cm-mobile-bread {
    padding-top: 8px;
    margin-bottom: 10px;
  }

  .yt-main-contact-us-pg {
    padding-top: 0;
    margin-bottom: 20px;
  }

  .yt-main-contact-us-pg .title {
    font-size: 18px;
    line-height: 1.11;
    margin-bottom: 10px;
  }

  .yt-main-contact-us-pg .group {
    padding: 30px 20px;
  }

  .yt-main-contact-us-pg label {
    font-size: 13px;
    line-height: 1.15;
    padding-left: 20px;
  }

  .yt-main-contact-us-pg input {
    padding: 7px 10px 7px 0px;
  }

  .yt-contact-send-btn {
    position: initial !important;
    width: 100% !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 1.17 !important;
    padding-top: 20px !important;
  }

  .yt-main-contact-us-pg .group:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-main-contact-us-pg .title {
    font-size: 26px;
  }
}