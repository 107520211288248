/* div {
    background-color: green;
} */
.logo {
  font-family: GTWalsheimPro;
  font-size: 50px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #324688;
}

.logo img {
  width: 100%;
  max-width: 190px;
  height: auto;
}

.mt-100 {
  margin-top: 100px;
}

.bottombar {
  /* height: 40px; */
  color: var(--color-black);
  font-size: 14px;
  margin: 6px 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.footercontainer {
  /* padding-top: 50px; */
  padding-top: 5%;
  background-color: #F8F9FA;
  padding-bottom: 44px;
}

.social_all a:first-child img {
  margin-left: 0;
}

.logo-img {
  height: 40px;
  width: 80px;
}

.data_underlogo {
  padding-top: 30px;
  height: 95px;
  width: 330px;
}

.navtitle {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-black);
  padding-top: 10px;
}

.navlist {
  font-weight: normal;
  text-decoration: none;
  color: var(--color-dark-grey);
  padding-top: 20px;
}

.navitem {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.promotionsimage {
  padding-top: 20px;
  padding-bottom: 10px;
  width: 270px;
  height: 150px;
}

.social_logos {
  width: 23px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.55s ease;
}

.social_logos:hover {
  transform: translateY(-3px);
  transition: all 0.55s ease;
}

.social_all {
  color: var(--color-primary);
}

.yt-footer-widget-title {
  /* font-family: Rubik; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-black);
  margin-bottom: 16px;
}

.quick-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
  margin-bottom: 15px !important;
}

.yt-footler-menu-links .yt-ftr-link {
  /* font-size: 15px; */
  font-size: 18px;
  line-height: 2;
  text-align: left;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-ftr-banner {
  margin-top: 6px;
}

.yt-text-about {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: #757575;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 30px;
  cursor: pointer;
}

.yt-text-about:hover {
  color: black;
}

.yt-copyright-text {
  /* font-family: Rubik; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
}

.emailinput {
  width: 100%;
  height: 40px;
}

.emailbutton {
  background-color: var(--button-primary);
  color: var(--button-text-primary);
  padding: 10px 35px;
  margin-top: 20px;
  border: none;
}

/* Started */
.dwn-app {
  font-family: GTWalsheimPro;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding-top: 60px;
  padding-bottom: 42px;
}

.download-app-banner {
  display: inline-grid;
}

.p-image {
  width: 138px;
  height: 53px;
  cursor: pointer;
}

.store-banner {
  margin-bottom: 80px;
}

.app-banner {
  padding-bottom: 60px;
}

.bg-img {
  background-image: url(../images/footerBackground.png);
  width: 100%;
  background-size: cover;
  background-repeat: "no-repeat";
  background-position: "center";
  position: "relative";
}

.youtube-style {
  height: 31px;
  width: 31px;
  color: #757575;
}

/* End */
@media (max-width: 1700px) {
  .logo img {
    width: 90%;
  }
}

@media (max-width: 1500px) {
  .logo img {
    width: 80%;
  }
}

@media (max-width: 1370px) {
  .mt-100 {
    margin-top: 70px;
  }

  .logo img {
    width: 65%;
  }
}

@media (max-width: 1280px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 93.3%;
  }

  .logo img {
    width: 60%;
  }
}

/*
  * Screen for under 1280 Resolution 
  */

@media (max-width: 1279.92px) {
  .mt-100 {
    margin-top: 50px;
  }

  .yt-col.yt-first-col {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .yt-foote-link-col,
  .yt-last-col {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .yt-footer-widget-title {
    font-size: 16px;
    line-height: 1.13;
    margin-bottom: 17px;
  }

  .yt-text-about {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: #757575;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 40%;
  }

  .social_all {
    padding: 0;
    margin-bottom: 17px;
  }

  .social_all .social_logos:first-child {
    margin-left: 10px;
  }

  .yt-footler-menu-links {
    margin-top: -6% !important;
  }

  .yt-footler-menu-links .yt-ftr-link {
    font-size: 14px;
    line-height: 1.9;
  }
}

/*
  * Screen for under 768 Resolution 
  */
@media (max-width: 900px) {
  .logo img {
    width: 55%;
  }
}

@media (max-width: 767.88px) {
  .mt-100 {
    margin-top: 15px;
  }

  .logo img {
    width: 40%;
  }

  .yt-last-col {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .yt-foote-link-col {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .yt-footer-widget-title,
  .yt-footler-menu-links {
    margin-bottom: 20px;
  }

  .yt-text-about {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    line-height: 1.83;
    margin-right: 20%;
  }

  .social_all {
    padding: 0;
    margin-bottom: 20px;
  }

  .social_all .social_logos:first-child {
    margin-left: 8px;
  }

  .yt-main-footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .yt-footer-widget-title {
    font-size: 14px;
    line-height: 1.14;
  }

  .yt-footler-menu-links .yt-ftr-link {
    font-size: 13px;
    line-height: 1.97;
  }
}

@media (max-width: 767.88px) {
  .logo img {
    width: 30%;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-footer-widget-title {
    font-size: 20px;
  }

  .yt-footler-menu-links .yt-ftr-link {
    font-size: 18px;
  }
}